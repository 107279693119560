import React, { useState } from 'react';
import './Dealership.css';

const DealershipPage = () => {
  const pincodes = [
    { pincode: '110001', address: 'Dealership Address 1: 123 Main St, Anytown, CA 12345' },
    { pincode: '120001', address: 'Dealership Address 2: 456 Elm St, Anothertown, CA 98765' },
    { pincode: '130001', address: 'Dealership Address 3: 789 Oak St, Thirdtown, CA 54321' },
  ];

  const [pincode, setPincode] = useState('');
  const [serviceable, setServiceable] = useState(null);
  const [address, setAddress] = useState('');

  const handlePincodeChange = (event) => {
    setPincode(event.target.value);
  };

  const handleSearch = () => {
    const selectedPincode = pincodes.find((pincodeObj) => pincodeObj.pincode === pincode);
    if (selectedPincode) {
      setServiceable(true);
      setAddress(selectedPincode.address);
    } else {
      setServiceable(false);
      setAddress('');
    }
  };

  return (
    <>
    <h1 className='our'>Our Presence</h1>
    
      <div className='map'>
      <p> At Aeonix, our reach spans across the globe, establishing a prominent presence in every corner of the world.Join us on a journey beyond ordinary, where Aeonix's futuristic designs and state-of-the-art technology converge to create a driving experience that transcends boundaries. Explore the world of Aeonix, where the future of mobility is now.</p>
        <img className = 'maps' src='map.jpg'/>
      </div>
    
  </>
  );
};

export default DealershipPage;