import { useLoader } from "@react-three/fiber";
import { EffectComposer, SSR, Bloom, LUT } from "@react-three/postprocessing";
import { useControls } from "leva";
import { LUTCubeLoader } from "postprocessing";

export function Effects() {

  return (
    <EffectComposer disableNormalPass>
      <SSR />
      <Bloom luminanceThreshold={0} mipmapBlur luminanceSmoothing={1} intensity={0.05} />
    </EffectComposer>
  );
}
