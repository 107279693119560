import React, { useEffect, useState } from "react";
import "./Store.css";

const handlebuy = async (code) => {
  try {
    const response = await fetch(`http://localhost:4321/create-checkout-session/${code}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({})
    });

    if (response.ok) {
      const jsonResponse = await response.json();
      window.open(jsonResponse.url, '_blank');
    } else {
      throw new Error('Network response was not ok.');
    }
  } catch (error) {
    console.error('Error:', error);
  }
};

function Store() {
  const [storeItems, setStoreItems] = useState([]);
  const [sortedBy, setSortedBy] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    async function getItems() {
      try {
        const response = await fetch('http://localhost:4321/store');
        const data = await response.json();

        if (Array.isArray(data.items)) {
          setStoreItems(data.items);
        } else {
          console.error('Invalid data format:', data);
        }
      } catch (error) {
        console.error(error);
      }
    }

    getItems();
  }, []);

  const sortItems = (criteria) => {
    const sortedItems = [...storeItems];
    let newSortDirection = 'asc';

    if (criteria === sortedBy) {
      // If already sorted by the same criteria, toggle the sort direction
      newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    }

    if (criteria === 'name') {
      sortedItems.sort((a, b) => {
        const compareResult = a.name.localeCompare(b.name);
        return newSortDirection === 'asc' ? compareResult : -compareResult;
      });
    } else if (criteria === 'price') {
      sortedItems.sort((a, b) => {
        const compareResult = a.price - b.price;
        return newSortDirection === 'asc' ? compareResult : -compareResult;
      });
    }

    setStoreItems(sortedItems);
    setSortedBy(criteria);
    setSortDirection(newSortDirection);
  };

  return (
    <div className="store">
      <h1>Welcome to AEONIX Store</h1>
      <h3>   </h3>
      <div><div id="remote">
        <button onClick={() => sortItems('name')}>
          Sort by Name {sortedBy === 'name' && `(${sortDirection})`}
        </button>
        <button onClick={() => sortItems('price')}>
          Sort by Price {sortedBy === 'price' && `(${sortDirection})`}
        </button>
        </div>
      </div>
      <div className="store-items">
        <div className="row">
          {storeItems.map((item) => (
            <div className="col" key={item.id}>
              <div className="card">
                <img src={item.image} alt={item.name} />
                <h3>{item.name}</h3>
                <p>${item.price}</p>
                <button onClick={() => handlebuy("price_1OKkbjSEA4du02STMgQq5cLk")}>
                  Buy now
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Store;
