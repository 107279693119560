  import React, { useRef } from "react";
  import { useGLTF } from "@react-three/drei";
  import { useFrame } from "@react-three/fiber";

const Datsun= (props) => {
  const model = useGLTF("/models/Loading.glb");
  const modelRef = useRef();    
 
  const rotateModel = () => {
    modelRef.current.rotation.x = Math.PI / 2;
    // Rotate the model on the Y-axis (you can adjust the rotation axis as needed)
    modelRef.current.rotation.y += 0.044;
  };
  
  useFrame(rotateModel);

  return (
    <group {...props} dispose={null} scale={[0.1, 0.1, 0.1]}>
      <primitive object={model.scene} ref={modelRef} position={[0, 0,0]} scale={[.21, .21, .21]} />

      
      <directionalLight intensity={1} />
      <pointLight intensity={1} position={[0, 5, 10]} /> 
      <ambientLight intensity={1} />
      <rectAreaLight intensity={1} position={[-500, 10, 0]} width={10} height={10} />
     
    </group>
  );
};

export default Datsun;
