import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";

export const Gallardo= (props) => {
  const model = useGLTF("/models/lambo_gallardo.glb");
  const modelRef = useRef();    
  
  const rotateModel = () => {
    // Rotate the model on the Y-axis (you can adjust the rotation axis as needed)
    modelRef.current.rotation.y += 0.0044;
  };

  useFrame(rotateModel);
  
  return (
    <group {...props} dispose={null} scale={[0.1, 0.1, 0.1]}>
      <primitive object={model.scene} ref={modelRef} position={[0, -11,0]} scale={[10, 10, 10]} />
      <boxGeometry position={[0, 60, 0]} />
      
      <directionalLight intensity={1} />
      <pointLight intensity={1} position={[0, 5, 10]} /> 
      <ambientLight intensity={1} />
      <rectAreaLight intensity={1} position={[-500, 10, 0]} width={10} height={10} />
     
    </group>
  );
};

export function Gallardo_Details(){
  const handlebuy = async (e) => {
    e.preventDefault();

   

    try {
        const response = await fetch(`http://localhost:4321/create-checkout-session/price_1OIceESEA4du02STzLEH5E4C`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({})
        });

        if (response.ok) {
            const jsonResponse = await response.json();
            window.open(jsonResponse.url, '_blank');
        } else {
            throw new Error('Network response was not ok.');
        }
    } catch (error) {
        console.error('Error:', error);
    }
};
  return <div id="Car-Details-Container">
    <div id="Car-Name">Gallardo</div>
    <div id ="Car-Specs-Container">
      <div id="Car-Specs-Title">SPECIFICATIONS</div>
      <div id="Car-Specs-List">
        <div id="Car-Specs-Item">Engine: <br /> 5.2 L V10</div>
        <div id="Car-Specs-Item">Horse Power: <br /> 610 hp</div>
        <div id="Car-Specs-Item">0-60: <br /> 3.2 seconds</div>
        <div id="Car-Specs-Item">Top Speed: <br /> 202 mph</div>
    </div></div>
    <div id="Car-Description">The Lamborghini Gallardo is a mid-engine, rear-wheel-drive sports car produced by the Italian automobile manufacturer Lamborghini from 2003 to 2013. It is one of the most successful models in Lamborghini's history, with over 14,000 units sold worldwide.</div>
    <div id="Car-Price">Price: $200,000</div>
    <button id="Car-Buy-Button"  onClick={handlebuy} >Buy</button>
    </div>
}
