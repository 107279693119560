// GoogleAuth.js

import React, { useState } from 'react';

import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import firebaseApp from './Firebase';


const GoogleAuth = (props) => {
  const [userDisplayName, setUserDisplayName] = useState('');

  const handleGoogleSignIn = async () => {
    const auth = getAuth(firebaseApp);
    const provider = new GoogleAuthProvider();
    
    try {
      const result = await signInWithPopup(auth, provider);

      const usermail = result.user.email;
      
      
      console.log(result.user.email);
      setUserDisplayName(result.user.displayName);

    //   fetch('http://localhost:4321/loginmail', {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify({  usermail})
    // })
    // .then((res) => {
        
    //     if (res.status === 200) {
          
            props.isAuth(true, usermail);
            
            // window.location.href = '/car-gallery';
    //         return res.json();
    //     }else{
    //         alert('Email not found');
    //     }

    //     return res;
    // })
    // .then((data) => {
    //     if (data && data.error) {
    //         alert(data.error);
    //     }
    // });



    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div>
      <button id="GAuth" onClick={handleGoogleSignIn}>Login with Google</button>
      {userDisplayName && <p>Welcome, {userDisplayName}!</p>}
    </div>
  );
};

export default GoogleAuth;



  
export const GoogleSignUp = (props) => {
  const [userDisplayName, setUserDisplayName] = useState('');

  const handleGoogleSignIn = async () => {
    const auth = getAuth(firebaseApp);
    const provider = new GoogleAuthProvider();
    
    try {
      const result = await signInWithPopup(auth, provider);

      const usermail = result.user.email;
      const username = result.user.displayName;
      const password = result.user.uid;
    

      

      
      
      setUserDisplayName(result.user.displayName);

      fetch('http://localhost:4321/signupmail', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({  usermail, username, password})
    })
    .then((res) => {
        
        if (res.status === 200) {
            props.isAuth(true, usermail);
            // props.useremail=usermail;
            
            // props.onChange(true, usermail);
            
            return res.json();
        }if(res.status===203){

          alert('Email already exists');
        }else{
            alert('Email already exists');
        }

        return res;
    })
    .then((data) => {
        if (data && data.error) {
            alert(data.error);
        }
    });



    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div>
      <button id="GAuth" onClick={handleGoogleSignIn}>Sign in with Google</button>
      {userDisplayName && <p>Welcome, {userDisplayName}!</p>}
    </div>
  );
};