import react, { useEffect } from 'react';
import './Grid.css';

const Grid = () => {


  const handleModel = () => {
    window.location.href = '/car-gallery'; };
  const handleStore = () => {
    window.location.href = '/store'; };
  const handleDealership = () => {
    window.location.href = '/dealership'; };
  const handleSustainability = () => {
    window.location.href = '/sustainability'; };

  useEffect(() => {
    const gridItems = document.querySelectorAll('.item');

    gridItems.forEach((item, index) => {
      setTimeout(() => {
        item.classList.add('show');
      }, index * 200);
    }, []);

    return () => {
      gridItems.forEach((item) => {
        item.classList.remove('show');
      });
    };
  }, []);
  return (
    <>
     <div className="grid">
      <div className="item">
        <div className="item">
        <img className="grid1" src="HomePage/car3.jpg" alt="car" /> <div className="grid-overlay"> 
          <h1>  Checkout our Online Store</h1> <hr />
          <p>
  Explore Aeonix's Online Store for top-notch car accessories. </p>
  <button onClick={handleStore} >Shop Now</button>
          
                  </div>

        </div>
      </div>
      <div className="item">
        <img className="grid2" src="HomePage/car5.jpg" alt="car" /> <div className="grid-overlay"> <h1>Driving Towards Sustainability</h1><hr />
<p>
   Our eco-friendly initiatives range from efficient electric propulsion systems to advanced energy-recapture technologies. 
</p>
<button onClick={handleSustainability}>Learn More</button>
</div>
      </div>
      <div className="item">
        <img className="grid3" src="HomePage/car6.jpg" alt="machine" /> <div className="grid-overlay"> <h1>Find Your Nearest Dealership</h1><hr />
<p>
  Use our Dealership Checker to locate the nearest Aeonix dealerships.  Find your nearest location and start your Aeonix journey today!
</p>

<button  onClick={handleDealership} >Find Dealership</button>

</div>
      </div>
      <div className="item">
        <img className="grid4" src="HomePage/car7.jpg" alt="car" /> <div className="grid-overlay"><h1>Discover Our Car Models</h1> <hr />
<p>
  Immerse yourself in the world of Aeonix's cutting-edge car models. Explore sleek designs, innovative technology, and unmatched performance.  Step into the future of automotive excellence!
</p>
<button  onClick={handleModel}>Explore Models</button>

 </div>
      </div>
    </div>
    </>
  );
};

export default Grid;
