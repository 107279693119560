
import './Video.css';
// import Typed from 'react-typed';

const Video = () => {
  const handleButtonClick = () => {
    const element = document.getElementsByClassName('description-div')[0];
    const windowHeight = window.innerHeight;
    const elementBottom = element.getBoundingClientRect().bottom;

    const scrollOffset = elementBottom - windowHeight + 25;
    window.scrollTo({
      top: scrollOffset,
      behavior: 'smooth',
    });
  };

  return (  
    <div className="video-container">
      <video
        controls="false"
        width="125%"
        // height="10%"
        src="HomePage/Lamborghini Huracán Super Trofeo EVO_ Official Video1.mp4"
        type="video/mp4"
        autoPlay
        muted
        loop
      ></video>
      <div className="video-overlay">
        <h1> AEONIX</h1>
        <h3>
          C  r  a  f  t  i  n  g &nbsp; T  o  m  o  r  r  o  w  '  s &nbsp; 
          {/* <Typed strings={["D  r  i  v  e", "O  d  y  s  s  e  y", "V  e  n  t  u  r  e"]}
            typeSpeed={50}
            backSpeed={45}
            loop
          />  
           */}
           D  r  i  v  e
          </h3>
       
        
      </div>
      <button className='VideoButton' onClick={handleButtonClick}> Continue </button>
    </div>
  );
};

export default Video;
