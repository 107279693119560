import React, { useRef } from "react";
import * as THREE from "three";
import { useMemo } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame, useLoader } from "@react-three/fiber";
import { applyProps } from "@react-three/fiber/";



export const Lambo= (props) => {
  const model = useGLTF("/models/lambo.glb");
  const modelRef = useRef();    
  const { scene, nodes, materials } = useGLTF('/models/lambo.glb')
  useMemo(() => {
    // ⬇⬇⬇ All this is probably better fixed in Blender ...
    Object.values(nodes).forEach((node) => {
      if (node.isMesh) {
        // Fix glas, normals look messed up in the original, most likely deformed meshes bc of compression :/
        if (node.name.startsWith('glass')) node.geometry.computeVertexNormals()
        // Fix logo, too dark
        if (node.name === 'silver_001_BreakDiscs_0') node.material = applyProps(materials.BreakDiscs.clone(), { color: '#ddd' })
      }
    })
    // Fix windows, they have to be inset some more
    nodes['glass_003'].scale.setScalar(2.7)
    // Fix inner frame, too light
    applyProps(materials.FrameBlack, { metalness: 0.75, roughness: 0, color: 'black' })
    // Wheels, change color from chrome to black matte
    applyProps(materials.Chrome, { metalness: 1, roughness: 0, color: '#333' })
    applyProps(materials.BreakDiscs, { metalness: 0.2, roughness: 0.2, color: '#555' })
    applyProps(materials.TiresGum, { metalness: 0, roughness: 0.4, color: '#181818' })
    applyProps(materials.GreyElements, { metalness: 0, color: '#292929' })
    // Make front and tail LEDs emit light
    applyProps(materials.emitbrake, { emissiveIntensity: 3, toneMapped: false })
    applyProps(materials.LightsFrontLed, { emissiveIntensity: 3, toneMapped: false })
    // Paint, from yellow to black
    nodes.yellow_WhiteCar_0.material = new THREE.MeshPhysicalMaterial({
      roughness: 0.3,
      metalness: 0.05,
      color: '#111',
      envMapIntensity: 0.75,
      clearcoatRoughness: 0,
      clearcoat: 1
    })
  }, [nodes, materials])
  const rotateModel = () => {
    // Rotate the model on the Y-axis (you can adjust the rotation axis as needed)
    modelRef.current.rotation.y += 0.0044;
  };

  useFrame(rotateModel);


 

  return (
    <group {...props} dispose={null} scale={[0.1, 0.1, 0.1]}>
      <primitive object={scene} ref={modelRef} position={[0, -5 ,0]} scale={[0.09, 0.09,0.09]} />
     
                 
                
                    <directionalLight intensity={1} />
                    <pointLight intensity={1} position={[0, 5, 10]} /> 
                    <ambientLight intensity={1} />
                    <rectAreaLight intensity={1} position={[0, 10, 0]} width={10} height={10} />

                   
                </group>
            );
        };


        export function Lambo_Details(){
          const handlebuy = async (e) => {
            e.preventDefault();
        
           
        
            try {
                const response = await fetch(`http://localhost:4321/create-checkout-session/price_1OIcVbSEA4du02ST6K3dGZs6`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({})
                });
        
                if (response.ok) {
                    const jsonResponse = await response.json();
                    window.open(jsonResponse.url, '_blank');
                } else {
                    throw new Error('Network response was not ok.');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };
          return <div id="Car-Details-Container">
            <div id="Car-Name">Urus</div>
            <div id ="Car-Specs-Container">
              <div id="Car-Specs-Title">SPECIFICATIONS</div>
              <div id="Car-Specs-List">
                <div id="Car-Specs-Item">Engine: <br /> 6.5 L V12</div>
                <div id="Car-Specs-Item">Horse Power: <br /> 700 hp</div>
                <div id="Car-Specs-Item">0-60: <br /> 2.9 seconds</div>
                <div id="Car-Specs-Item">Top Speed: <br /> 217 mph</div>
            </div></div>
            <div id="Car-Description">The Lamborghini Urus is a mid-engine sports car produced by the Italian automobile manufacturer Lamborghini. It is known for its aggressive design and powerful performance. The Aventador is equipped with a 6.5-liter V12 engine that produces 700 Horse Power: <br />, allowing it to accelerate from 0 to 60 mph in just 2.9 seconds and reach a top speed of 217 mph.</div>
            <div id="Car-Price">Price: $500,000</div>
            <button id="Car-Buy-Button" onClick={handlebuy} >Buy Now</button>
            </div>
        }