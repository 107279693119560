import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";

export const Countach= (props) => {
  const model = useGLTF("/models/mclaren.glb");
  const modelRef = useRef();    
  
  const rotateModel = () => {
    // Rotate the model on the Y-axis (you can adjust the rotation axis as needed)
    modelRef.current.rotation.y += 0.0044;
  };

  useFrame(rotateModel);

  return (
    <group {...props} dispose={null} scale={[0.1, 0.1, 0.1]}>
      <primitive object={model.scene} ref={modelRef} position={[0, -11.9,0]} scale={[10, 10, 10]} />
     
      
      <directionalLight intensity={1} />
      <pointLight intensity={1} position={[0, 5, 10]} /> 
      <ambientLight intensity={1} />
      <rectAreaLight intensity={1} position={[-500, 10, 0]} width={10} height={10} />
     
    </group>
  );
};


export function Countach_Details(){


  const handlebuy = async (e) => {
    e.preventDefault();

   

    try {
        const response = await fetch(`http://localhost:4321/create-checkout-session/price_1OIcc9SEA4du02STfwF4oD7L`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({})
        });

        if (response.ok) {
            const jsonResponse = await response.json();
            window.open(jsonResponse.url, '_blank');
        } else {
            throw new Error('Network response was not ok.');
        }
    } catch (error) {
        console.error('Error:', error);
    }
};

  return (
    <div id="Car-Details-Container">
      <div id="Car-Name">Countach</div>
      <div id ="Car-Specs-Container">
        <div id="Car-Specs-Title">SPECIFICATIONS</div>
        <div id="Car-Specs-List">
          <div id="Car-Specs-Item">Engine: <br /> 3.9 L V12</div>
          <div id="Car-Specs-Item">Horse Power: <br /> 370 hp</div>
          <div id="Car-Specs-Item">0-60: <br /> 5.6 seconds</div>
          <div id="Car-Specs-Item">Top Speed: <br /> 183 mph</div>
        </div>
      </div>
      <div id="Car-Description">The Lamborghini Countach is a rear mid-engine, rear-wheel-drive sports car produced by the Italian automobile manufacturer Lamborghini from 1974 to 1990. It is one of the many exotic designs developed by Italian design house Bertone, which pioneered and popularized the sharply angled "Italian Wedge" design language.</div>
      <div id="Car-Price">Price: $1.5 million</div>
      <button id="Car-Buy-Button" onClick={handlebuy}>Buy</button>
    </div>
  );
};
