// firebase.js

import { initializeApp } from 'firebase/app';

const firebaseConfig = {

  apiKey: "AIzaSyAWTgv6pcveNbBCmiR9MoP_i19WiDanxMY",

  authDomain: "aeonix-1768f.firebaseapp.com",

  projectId: "aeonix-1768f",

  storageBucket: "aeonix-1768f.appspot.com",

  messagingSenderId: "425529620182",

  appId: "1:425529620182:web:49e8a576e9962a0ff23b77",

  measurementId: "G-N2XJZXSDTC"

};



const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
