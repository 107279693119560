import React , {Suspense} from 'react';
import Navbar from './Components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CarGallery from './CarGallery/NewCarShow';
import {SignUp} from './Components/SignUp';
import Cookie from 'js-cookie';
import {Login} from './Components/LogIn'; 
import Footer from  './Components/Footer';
import Store from './Store/Store';
import Store1 from './Store/Store1';
import Home from './Home/Home';
import Sustainability   from './Components/Sustainability'; 
import Dealership from './Components/Dealership';
import {useEffect} from 'react';

import './style.css';
import { useSpring, animated } from 'react-spring';
import { useParams } from 'wouter';




function NoPath(){
    return(
        <div style={{ textAlign: 'center', color: 'white', height: '50vh'}}>
            <h1  style={{'margin': '12.5rem' ,fontSize: '3.5rem'  , fontFamily:'Orbitron' ,fontStyle:'monospaced'    }}  >404 Page Not Found</h1>
        </div>
    );
}


function App(){
    const[isAuthenticated, setIsAuthenticated] = React.useState(false);
    const[username, setUsername] = React.useState('');
    const[SignUporLogIn, setSignUporLogIn] = React.useState(true);
    React.useEffect(() => {
        const auth = Cookie.get('isAuthenticated');
        const user = Cookie.get('username');
        if(auth === 'true'){
            setIsAuthenticated(true);
            setUsername(user);
        }
    }, []);
    const handleAuthChange = (value, username) => {
       
        Cookie.set('isAuthenticated', value);
        Cookie.set('username', username);
        setIsAuthenticated(value);
        setUsername(username);
    }
    

 

    // ...

    const signUpAnimation = useSpring({
        opacity: SignUporLogIn ? 1 : 0,
        transform: SignUporLogIn ? 'rotateY(0deg)' : 'rotateY(-180deg)',
    });

    const loginAnimation = useSpring({
        opacity: SignUporLogIn ? 0 : 1,
        transform: SignUporLogIn ? 'rotateY(-180deg)' : 'rotateY(0deg)',
    });

    return (
        <><div class="desktop-notice">
        </div>
            <Navbar />
          
           {!isAuthenticated && <div className="PreLogin">
                {!isAuthenticated && (
                   <button
                        className={`SignUporLogInButton `}
                        
                        onClick={() => setSignUporLogIn(!SignUporLogIn)}
                    >
                        {SignUporLogIn ? 'Are you a new user? Sign Up' : 'Already have an account? Log In.'}
                    </button>
                )}
                {!isAuthenticated && (
                    <animated.div style={signUpAnimation}>
                        {SignUporLogIn && <Login onChange={handleAuthChange} />}
                    </animated.div>
                )}
                {!isAuthenticated && (
                    <animated.div style={loginAnimation}>
                        {!SignUporLogIn && <SignUp onChange={handleAuthChange} />}
                    </animated.div>
                )}
            </div>}
           
        
        {isAuthenticated && 
        <Router>
            <SignOut />
            
            <Routes>
                <Route exact path="/" element={ <Home />} />
                
                <Route
                    path="/car-gallery"
                    element={<CarGallery />} />
                <Route path="/dealership" element={<Dealership />} />
                <Route path="/store" element={<Store1 />} />
                <Route path="/sustainability" element={<Sustainability />} />
                <Route path="*" element={<NoPath />} />
            </Routes>
      
        </Router>}
        <Footer />
        </>
    );
}
export default App;



function SignOut(){
    const handleSignOut = () => {
        Cookie.remove('isAuthenticated');
        Cookie.remove('username');
        window.location.href = '/';
    }
    return(
        <button className="SignOutButton"onClick={handleSignOut}>Sign Out</button>
    );
}