import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";

export const Revuelto= (props) => {
  const model = useGLTF("/models/lambo_revuelto.glb");
  const modelRef = useRef();    
  
  const rotateModel = () => {
    // Rotate the model on the Y-axis (you can adjust the rotation axis as needed)
    modelRef.current.rotation.y += 0.0084;
  };

  useFrame(rotateModel);
  
  return (
    <group {...props} dispose={null} scale={[0.1, 0.1, 0.1]}>
      <primitive object={model.scene} ref={modelRef} position={[0, -11.2,0]} scale={[9, 9, 9]} />
     
     
    </group>
  );
};

export function Revuelto_Details(){
  const handlebuy = async (e) => {
    e.preventDefault();

   

    try {
        const response = await fetch(`http://localhost:4321/create-checkout-session/price_1OIcfzSEA4du02ST0a4fnMun`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({})
        });

        if (response.ok) {
            const jsonResponse = await response.json();
            window.open(jsonResponse.url, '_blank');
        } else {
            throw new Error('Network response was not ok.');
        }
    } catch (error) {
        console.error('Error:', error);
    }
};
  return <div id="Car-Details-Container">
    <div id="Car-Name">Revuelto</div>
    <div id ="Car-Specs-Container">
      <div id="Car-Specs-Title">SPECIFICATIONS</div>
      <div id="Car-Specs-List">
        <div id="Car-Specs-Item">Engine: <br /> 6.2 L V8</div>
        <div id="Car-Specs-Item">Horse Power: <br /> 650 hp</div>
        <div id="Car-Specs-Item">0-60: <br /> 3.5 seconds</div>
        <div id="Car-Specs-Item">Top Speed: <br /> 205 mph</div>
    </div></div>
    <div id="Car-Description">The Revuelto is a high-performance sports car produced by Lamborghini. It features a powerful 6.2 L V8 engine, delivering 650 Horse Power: <br />. With a 0-60 acceleration time of 3.5 seconds and a top speed of 205 mph, it offers an exhilarating driving experience.  Experience the thrill of driving with the Revuelto.</div>
    <div id="Car-Price">Price: $1.8 million</div>
    <button id="Car-Buy-Button"   onClick={handlebuy}>Buy</button>
    </div>
}