import React, { useState } from "react";
import "./Sustainability.css";

const Sustainability = () => {
  return (
    <>
      <div className="sustainability-container">
        <div>
          <h1 className="sustainability-title">SUSTAINABILITY AT AEONIX</h1>

          <div className="images">
            <img className = 'suses' src="sus1.jpeg" alt="" />
            <img className = 'suses' src="sus2.jpeg" alt="" />
            <img className = 'suses' src="sus3.jpeg" alt="" />
            <img className = 'suses' src="sus4.jpg" alt="" />
            <img className = 'suses' src="sus5.jpeg" alt="" />
          </div>

          <p className="sustainability-description">
            Drive the future with Aeonix. Our premium cars combine luxury and
            eco-responsibility, setting a new standard for <br/>sustainable
            automotive manufacturing. Recognized by the UN for exceptionally low
            emissions, <br/>Aeonix is your choice for a greener tomorrow.
          </p>
          {/* <p className="sustainability-description">
          
        </p> */}
        </div>

        <div className="recycle">
          <h2 className="sustainability-subtitle">Recycling of Materials</h2>
          <p className="sustainability-recycle">
            We go beyond industry standards in our recycling efforts. All
            materials used in the production of our <br />
            cars are carefully selected and recycled whenever possible. This
            meticulous approach not only ensures premium quality <br />
            but also helps us reduce waste, contributing to a more sustainable
            manufacturing process.
          </p>
          {/* <img
          className="sustainability-image"
          src="recycle.png"
          alt="Recycling of Materials"
        /> */}
        </div>

        <div className="low">
          <h2 className="sustainability-subtitle">Low Emissions</h2>
          <p className="sustainability-low">
            Our commitment to environmental stewardship is evident in our
            engineering. Aeonix cars are designed for <br />
            minimal impact on air quality, achieving remarkably low emissions.
            Advanced technologies and eco-friendly components work in <br />
            harmony to uphold our promise of providing environmentally conscious
            transportation solutions.
          </p>
          {/* <img
          className="sustainability-image"
          src="low-emissions.jpg"
          alt="Low Emissions"
        /> */}
        </div>

        <div className="recognition">
          <h2 className="sustainability-subtitle">
            United Nations Recognition
          </h2>
          <p className="sustainability-recognition">
            Aeonix is honored to have received recognition from the United
            Nations for our exemplary efforts in achieving <br />
            and maintaining extremely low emissions in our vehicles. This
            acknowledgment motivates us to continually innovate and <br />
            lead the way towards a greener and more sustainable automotive
            future.
          </p>
          {/* <img
          className="sustainability-image"
          src="un-recognition.jpg"
          alt="UN Recognition"
        /> */}
        </div>

        <div className="renewable">
          <h2 className="sustainability-subtitle">
            Renewable Energy Initiatives
          </h2>
          <p className="sustainability-renewable">
            Aeonix is actively involved in harnessing renewable energy to power
            our operations. From solar-powered facilities
            <br /> to energy-efficient manufacturing processes, we strive to
            reduce our carbon footprint and promote the use of clean energy
            sources.
          </p>
          {/* <img
          className="sustainability-image"
          src="renewable-energy.jpg"
          alt="Renewable Energy Initiatives"
        /> */}
        </div>

        <div className="community">
          <h2 className="sustainability-subtitle">Community Engagement</h2>
          <p className="sustainability-community">
            Sustainability is not just about our products; it's about making a
            positive impact on the communities we serve. Aeonix is dedicated{" "}
            <br />
            to community engagement, supporting environmental education, and
            participating in local initiatives that contribute to a healthier
            planet.
          </p>
          {/* <img
          className="sustainability-image"
          src="community-engagement.jpg"
          alt="Community Engagement"
        /> */}
        </div>
      </div>
    </>
  );
};
export default Sustainability;