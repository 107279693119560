import React, { useState } from 'react';
import './SignUp.css';
import {GoogleSignUp} from './GoogleAuth/GoogleAuth.js';

 export const SignUp = (props) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState(''); 

    const [Name, setName] = useState('');
    const [Email, setEmail] = useState('');
    const [useremail, setUserEmail] = useState('');

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };
    const handleNameChange = (e) => {
        setName(e.target.value);
    }
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }
    const handlePassword2Change = (e) => {
        setPassword2(e.target.value);
    }
    const handleGoogleEmailChange = (e) => {
        setEmail(e.target.value);
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(Email)) {
            alert('Email is not correct');
            return;
        }
        if(password !== password2){
            alert('Passwords do not match');    
            return;
        }

        console.log('Username:', username);
        console.log('Password:', password);
        fetch('http://localhost:4321/signup', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, password, Name, Email })
        })
        .then((res) => {
            if (res.status === 200) {
                props.onChange(true, username);
                
                return res.json();
            }
            return res;
        })
        .then((data) => {
            if (data && data.error) {
                alert(data.error);
            }
        });
        
    };

    return (
        <div className="signupandlogin">
        <div id="signup-container">
            <h2 id="signup-title">Sign Up</h2>
            <form onSubmit={handleSubmit} className="signup-form">
                <label htmlFor="username-input">
                    Username: <br />
                    <input
                        type="text"
                        id="username-input"
                        value={username}
                        onChange={handleUsernameChange}
                        className="input-field"
                    />
                </label>
                <br />
                <label htmlFor="password-input">
                    Password:<br />
                    <input
                        type="password"
                        id="password-input"
                        value={password}
                        onChange={handlePasswordChange}
                        className="input-field"
                    />
                </label>
                <br />
                <label htmlFor="password-input">
                    Password: <br />
                    <input
                        type="password"
                        id="password-input"
                        value={password2}
                        onChange={handlePassword2Change}
                        className="input-field"
                    />
                </label>
                <br />
                <label htmlFor="Name-input">
                    Name: <br />
                    <input
                        type="text"
                        id="Name-input"
                        value={Name}
                        onChange={handleNameChange}
                        className="input-field"
                    />
                </label>
                <br />
                <label htmlFor="Email-input">
                    Email: <br />
                    <input
                        type= "email"
                        id="Email-input"
                        value={Email}
                        onChange={handleEmailChange}
                        className="input-field"
                    /></label>
                
                <button type="submit" className="signup-button">Sign Up</button>
            </form>
            <GoogleSignUp useremail={useremail} isAuth={props.onChange} onChange={handleGoogleEmailChange} />
        </div>
        <div className="signuptext">
            <p>Welcome to AEONIX, the home of futuristic cars! Join us to elevate your transportation experience. </p>
               </div>
        
        </div>
    );
};


