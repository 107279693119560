import { Canvas
 } from '@react-three/fiber';

import * as THREE from 'three'
import { Environment, Lightformer, ContactShadows, OrbitControls, MeshReflectorMaterial, SpotLight } from '@react-three/drei'
import { Effects } from './Effects'
import {Lambo, Lambo_Details}  from './Components/modelrender/Lambo'
import { Suspense } from 'react'
import Loading from './Components/loading'
import {Porsche, Porsche_Details} from './Components/modelrender/model1'
import {Datsun, Datsun_Details} from './Components/modelrender/Datsun'
import {Maserati, Maserati_Details} from './Components/modelrender/Maserati'
import {Tesla,Tesla_Details} from './Components/modelrender/Tesla'

import {Revuelto, Revuelto_Details} from './Components/modelrender/Revuelto'
import {Gallardo, Gallardo_Details} from './Components/modelrender/Gallardo'
import {Countach, Countach_Details} from './Components/modelrender/Countach'
import { useState } from 'react'
import { useEffect } from 'react';
import './NewCarShow.css';
function previous(num){
  if(num==1){ 
    return 8;
  }
  else{
    return num-1;
  }
};
function next(num){
  if(num==8){
    return 1;
  }else{
    return num+1;
  }
};

export default function App(props) {
  const [selectedComponent, setSelectedComponent] = useState(null);
useEffect(() => {
  setSelectedComponent(props.selectedCar);
}, []);
  const handleButtonClick = (component) => {
      setSelectedComponent(component);
  };

  useEffect(() => {
    handleButtonClick(1);
  }, []);
  const texture1 = new THREE.MeshPhysicalMaterial({  roughness: 0.3,
    metalness: 0.05,
    color: '#111',
    envMapIntensity: 0.75,
    clearcoatRoughness: 0,
    clearcoat: 1
  })

  return (
    <>
    
  
       <div id="navigation" >
    <button onClick={() => handleButtonClick(   previous(selectedComponent))}>Previous</button>
    <button onClick={() => handleButtonClick(1)}>Porsche</button>
    <button onClick={() => handleButtonClick(2)}>Lamborghini</button>
    <button onClick={() => handleButtonClick(3)}>Datsun</button>
    <button onClick={() => handleButtonClick(4)}>Maserati</button>
    <button onClick={() =>  handleButtonClick(5)}>Tesla</button>
   
    <button onClick={() =>  handleButtonClick(6)}>Revuelto</button>
    <button onClick={() =>  handleButtonClick(7)}>Gallardo</button>
    <button onClick={() =>  handleButtonClick(8)}>Countach</button>
    <button onClick={() =>  handleButtonClick( next(selectedComponent)  )}>Next</button>
    
</div>

{selectedComponent === 2 && (<Lambo_Details />)}
{selectedComponent === 1 && (<Porsche_Details />)}
{selectedComponent === 3 && (<Datsun_Details />)}
{selectedComponent === 4 && (<Maserati_Details />)}
{selectedComponent === 5 && (<Tesla_Details />)}
{selectedComponent === 6 && (<Revuelto_Details />)}
{selectedComponent === 7 && (<Gallardo_Details />)}
{selectedComponent === 8 && (<Countach_Details />)}


<Suspense fallback={ <><fog attach="fog" args={["#111111", 10, 50]} />
      <color attach="background" args={["#111111"]} /></>}>
<Canvas
      gl={{ logarithmicDepthBuffer: true, antialias: false }}
      dpr={[1, 1.5]}
      camera={{ position: [3, 0.5, 10], fov: 25, far: 50 }}
    > 
    
      <ContactShadows
        resolution={1024}
        frames={10}
        position={[0, -16, 0]}
        scale={15000}
        blur={0.5}
        opacity={0.5}
        far={2000}
        
      />
    <fog attach="fog" args={["#111111", 10, 50]} />
      <color attach="background" args={["#111111"]} />
      
     < Suspense fallback= {<Loading />}>
    {selectedComponent === 1 && (
      <>
        <Porsche receiveShadow emitShadow rotation={[0, Math.PI / 1.5, 0]} scale={0.015} />
      </>
    )}
    {selectedComponent === 2 && (
      <>
        <Lambo rotation={[0, Math.PI / 1.5, 0]} scale={0.015} />
      </>
    )}
    {selectedComponent === 3 && (
      <>
        <Datsun rotation={[0, Math.PI / 1.5, 0]} scale={0.015} />
      </>
    )}
    {selectedComponent === 4 && (
      <>
        <Maserati rotation={[0, Math.PI / 1.5, 0]} scale={0.015} />
      </>
    )}
    {selectedComponent === 5 && (
      <>
        <Tesla rotation={[0, Math.PI / 1.5, 0]} scale={0.015} />
      </>
    )}

    {selectedComponent === 6 && (
      <>
        <Revuelto rotation={[0, Math.PI / 1.5, 0]} scale={0.015} />
      </>
    )}
    {selectedComponent === 7 && (
      <>
        <Gallardo rotation={[0, Math.PI / 1.5, 0]} scale={0.015} />
      </>
    )}
    {selectedComponent === 8 && (
      <>
        <Countach rotation={[0, Math.PI / 1.5, 0]} scale={0.015} />
      </>
    )}
    </Suspense>
  {/* ... */}
        
  
      <hemisphereLight intensity={0.5} />
    
     
      <mesh
        scale={4}
        position={[3, -1.161, -1.5]}
        rotation={[-Math.PI / 2, 0, Math.PI / 2.5]}
      >
        <ringGeometry args={[0.9, 1, 4, 1]} />
        <meshPhongMaterial color="white"  emissiveIntensity={0.2} />
        <ambientLight intensity={3} />
      </mesh>
      <mesh
        scale={4}
        position={[-3, -1.161, -1]}
        rotation={[-Math.PI / 2, 0, Math.PI / 2.5]}
      >
        <ringGeometry args={[0.9, 1, 3, 1]} />
        <meshStandardMaterial color="white"  emissiveIntensity={0.2} />
        <ambientLight intensity={3} />
      </mesh>
      {/* We're building a cube-mapped environment declaratively.
          Anything you put in here will be filmed (once) by a cubemap-camera
          and applied to the scenes environment, and optionally background. */}

      <Environment resolution={512}>
        {/* Ceiling */}
        <Lightformer
          intensity={2}
          rotation-x={Math.PI / 2}
          position={[0, 4, -9]}
          scale={[10, 1, 1]}
        />
        <Lightformer
          intensity={2}
          rotation-x={Math.PI / 2}
          position={[0, 4, -6]}
          scale={[10, 1, 1]}
        />
        <Lightformer
          intensity={2}
          rotation-x={Math.PI / 2}
          position={[0, 4, -3]}
          scale={[10, 1, 1]}
        />
        <Lightformer
          intensity={2}
          rotation-x={Math.PI / 2}
          position={[0, 4, 0]}
          scale={[10, 1, 1]}
        />
        <Lightformer
          intensity={2}
          rotation-x={Math.PI / 2}
          position={[0, 4, 3]}
          scale={[10, 1, 1]}
        />
        <Lightformer
          intensity={2}
          rotation-x={Math.PI / 2}
          position={[0, 4, 6]}
          scale={[10, 1, 1]}
        />
        <Lightformer
          intensity={2}
          rotation-x={Math.PI / 2}
          position={[0, 4, 9]}
          scale={[10, 1, 1]}
        />
        {/* Sides */}
        <Lightformer
          intensity={2}
          rotation-y={Math.PI / 2}
          position={[-50, 2, 0]}
          scale={[100, 2, 1]}
        />
        <Lightformer
          intensity={2}
          rotation-y={-Math.PI / 2}
          position={[50, 2, 0]}
          scale={[100, 2, 1]}
        />
        {/* Key */}
        <Lightformer
          form="ring"
          color="red"
          intensity={10}
          scale={2}
          position={[10, 5, 10]}
          onUpdate={(self) => self.lookAt(0, 0, 0)}
        />
      </Environment>

      {selectedComponent != 8 && (
      <Effects />
    )}
      <OrbitControls enableZoom={false } enablePan={false} target={[0,0,0] } maxPolarAngle={Math.PI/2}  />
    </Canvas></Suspense></>
  );
}
