import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";

export const Datsun= (props) => {
  const model = useGLTF("/models/datsun-transformed.glb");
  const modelRef = useRef();    
  
  const rotateModel = () => {
    // Rotate the model on the Y-axis (you can adjust the rotation axis as needed)
    modelRef.current.rotation.y += 0.0044;
  };

  useFrame(rotateModel);
 
  return (
    <group {...props} dispose={null} scale={[0.1, 0.1, 0.1]}>
      <primitive object={model.scene} ref={modelRef} position={[0, -8,0]} scale={[9, 9, 9]} />
      <boxGeometry position={[0, 60, 0]} />
      
      <directionalLight intensity={1} />
      <pointLight intensity={1} position={[0, 5, 10]} /> 
      <ambientLight intensity={1} />
      <rectAreaLight intensity={1} position={[-500, 10, 0]} width={10} height={10} />
     
    </group>
  );
};

export function Datsun_Details(){
  const handlebuy1 = async (e) => {
    e.preventDefault();
  try {
        const response = await fetch(`http://localhost:4321/create-checkout-session/price_1OIcd3SEA4du02STbzy3Dqhu`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({})
        });

        if (response.ok) {
            const jsonResponse = await response.json();
            window.open(jsonResponse.url, '_blank');
        } else {
            throw new Error('Network response was not ok.');
        }
    } catch (error) {
        console.error('Error:', error);
    }
};
  return <div id="Car-Details-Container">
    <div id="Car-Name">Datsun</div>
    <div id ="Car-Specs-Container">
      <div id="Car-Specs-Title">SPECIFICATIONS</div>
      <div id="Car-Specs-List">
        <div id="Car-Specs-Item">Engine: <br /> 2.0 L Inline-4</div>
        <div id="Car-Specs-Item">Horse Power: <br /> 200 hp</div>
        <div id="Car-Specs-Item">0-60: <br /> 6.5 seconds</div>
        <div id="Car-Specs-Item">Top Speed: <br /> 140 mph</div>
    </div></div>
    <div id="Car-Description">The Datsun is a classic Japanese sports car known for its sleek design and reliable performance. It was produced by Datsun, a subsidiary of Nissan, from 1969 to 1978. The Datsun gained popularity for its affordability and fun driving experience.</div>
    <div id="Car-Price">Price: $50,000</div>
    <button id="Car-Buy-Button" onClick={handlebuy1} >Buy</button>
    </div>
}
