import React, { useState } from 'react';
import './SignUp.css';
import GAuth from './GoogleAuth/GoogleAuth.js';

export const Login = (props) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    


    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
        
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);

    };



    const handleSubmit = (e) => {
        e.preventDefault();

        // Email validation
     

            
            if (username === 'admin' || password === 'password') {
                props.onChange(true, username);

                
            }else{
                alert('Username or Password is incorrect');
            }

      
    };

    return (
        <div className="signupandlogin">
             <div className="logintext">
                           <p> AEONIX- The Home of futuristic cars! Return to take your transcendence to the next level. </p>
                           
                        </div>
        <div id="login-container">
            <h2 id="login-title">Log In</h2>
            <form onSubmit={handleSubmit} className="login-form">
                <label htmlFor="username-input">
                    Username:<br></br>
                    <input
                        type="text"
                        id="username-input"
                        value={username}
                        onChange={handleUsernameChange}
                        className="input-field"
                    />
                </label>
                <br />
                <label htmlFor="password-input">
                    Password:<br></br>
                    <input
                        type="password"
                        id="password-input"
                        value={password}
                        onChange={handlePasswordChange}
                        className="input-field"
                    />
                </label>
                <br />
                <button type="submit" className="login-button">Log In</button>
            </form>
            <GAuth isAuth={props.onChange}/>
        </div></div>
    );
};


