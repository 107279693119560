
// import logo from './logo.svg';
import './Home.css';

import Video from './Video.js';
import Grid from './Grid.js';
import React, { useEffect, useState, useRef } from 'react';


function Home() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isAnimationActive, setAnimationActive] = useState(false);
  const descriptionMainDivRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    // Attach the event listener
    window.addEventListener('scroll', handleScroll);

    // Detach the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const descriptionMainDiv = descriptionMainDivRef.current;

    if (!descriptionMainDiv) return;

    const descriptionMainDivRect = descriptionMainDiv.getBoundingClientRect();
    const viewportHeight = window.innerHeight;

    const divTop = descriptionMainDivRect.top;
    const divBottom = descriptionMainDivRect.bottom;
    const divCenter = (divTop + divBottom) / 2;

    const isDivCentered = divTop <= viewportHeight / 2 && divBottom >= viewportHeight / 4 ;
    

    let translationValue =0;
    if (divTop<=viewportHeight/8) {
      // If the div is centered, calculate the translation value based on the scroll position
      translationValue = window.innerWidth * 2.15;
    }else if (divTop >= viewportHeight/8 && divBottom<= 7*viewportHeight/8) {
      // If the div is below the viewport center, set the translation value to 0
      translationValue = window.innerWidth * 2.15/2;

    }
    else if(divBottom>=7*viewportHeight/8) {
      // If the div is above the viewport center, set the translation value to the div height
 
      translationValue=0;
     
    }

    // Lock or unlock body scrolling based on animation state
    // document.body.style.overflowY     = isAnimationActive ? 'hidden' : 'hidden';

    descriptionMainDiv.style.transform = `translateX(-${translationValue}px)`;
    descriptionMainDiv.style.transition = isDivCentered ? 'transform 0.3s linear' : 'none';
  }, [scrollPosition, isAnimationActive]);

  return (
    <>
      <Video />
      <div ref={descriptionMainDivRef} className='description-maindiv'>
      
      
        <div className='description-div' id="desc-div1">
          <h1>Innovative Excellence</h1>
          <p>
            Introducing Aeonix, a pioneer at the forefront of automotive innovation. Our cutting-edge, futuristic cars redefine transportation norms, seamlessly blending state-of-the-art technology with sleek design. Aeonix vehicles are not just modes of transportation; they are bold statements of progress and sophistication.
          </p>
        </div>

        <div className='description-div' id="desc-div2">
          <h1>Sustainable Driving</h1>
          <p>
            Committed to sustainable mobility, Aeonix takes a proactive stance toward environmental responsibility. Our cars integrate eco-friendly features without compromising performance, from efficient electric propulsion systems to advanced energy-recapture technologies. Aeonix ensures that every journey contributes to a cleaner, greener future.
          </p>
        </div>

        <div className='description-div' id="desc-div3">
          <h1>Tomorrow's Roads Today</h1>
          <p>
            Step into the future with Aeonix, where every drive is an experience in automotive evolution. Our commitment to shaping tomorrow's roads today is evident in the intelligent design, cutting-edge technology, and eco-conscious features of our vehicles. Elevate your driving experience with Aeonix – setting the standard for the cars of tomorrow.
          </p>
        </div>
      </div>
      <Grid />
    
    </>
  );
}

export default Home;
