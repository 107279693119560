import React from 'react';
import './Navbar.css';
const Navbar = () => {
  return (
    
      <nav class="navbar navbar-expand-lg bg-body-tertiary-inline">
        <div class="collapse navbar-collapse" id="navbarNav">
          
            <ul class="navbar-nav">
            <li class="nav-item">
                <a href="/" class="nav-link" id="aeonixnav">AEONIX</a>
            </li>
              
              <li class="nav-item">
                <a class="nav-link " href="/car-gallery">Models </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/dealership">Dealerships</a>
              </li>
       
              
              <li class="nav-item">
                <a class="nav-link " href="/store">Store</a>
              </li>
              {/* <li class="nav-item">
                <a class="nav-link" href="/sustainability">Sustenance</a>
              </li> */}
              
            </ul>
          </div>
       
      </nav>
    
  );
};

export default Navbar;
