import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";

export const Porsche = (props) => {
  const model = useGLTF("/models/car.glb");
  const modelRef = useRef();    
  
  const rotateModel = () => {
    // Rotate the model on the Y-axis (you can adjust the rotation axis as needed)
    modelRef.current.rotation.y += 0.0044;
  };

  useFrame(rotateModel);

  return (
    <group {...props} dispose={null} scale={[0.1, 0.1, 0.1]}>
      <primitive object={model.scene} ref={modelRef} position={[0, -6, 0]} scale={[10, 10,10]} />
      
      
      <directionalLight intensity={1} />
      <pointLight intensity={1} position={[0, 5, 10]} /> 
      <ambientLight intensity={1} />
      <rectAreaLight intensity={1} position={[10, 10, 10]} width={10} height={10} />
     
    </group>
  );
};
export function Porsche_Details(){

  const handlebuy = async (e) => {
    e.preventDefault();

   

    try {
        const response = await fetch(`http://localhost:4321/create-checkout-session/price_1OIbEvSEA4du02STMewSOyVj`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({})
        });

        if (response.ok) {
            const jsonResponse = await response.json();
            window.open(jsonResponse.url, '_blank');
        } else {
            throw new Error('Network response was not ok.');
        }
    } catch (error) {
        console.error('Error:', error);
    }
};



  return <div id="Car-Details-Container">
    <div id="Car-Name">Porsche</div>
    <div id ="Car-Specs-Container">
      <div id="Car-Specs-Title">SPECIFICATIONS</div>
      <div id="Car-Specs-List">
        <div id="Car-Specs-Item">Engine: <br /> 3.0 L V6</div>
        <div id="Car-Specs-Item">Horse Power: <br /> 450 hp</div>
        <div id="Car-Specs-Item">0-60: <br /> 3.5 seconds</div>
        <div id="Car-Specs-Item">Top Speed: <br /> 190 mph</div>
    </div></div>
    <div id="Car-Description">The Porsche is a high-performance sports car produced by the German automobile manufacturer Porsche AG. It is known for its sleek design, powerful engine, and exceptional handling.</div>
    <div id="Car-Price">Price: $100,000</div>
    <button id="Car-Buy-Button" onClick={handlebuy}>Buy</button>
    </div>
}
