import react from 'react';
import './footer.css';

const Footer = () => {
  return (
   
      <footer>
        <div className="about-us-container">
          <div className="row">
            <div className="col-md-4">
              <h6 className="foottopic">About Us</h6>
              <p className="footdesc">
                Learn about our history, mission, and values.
              </p>
            </div>
            <div className="col-md-4">
              <h6 className="foottopic">Contact Us</h6>
              <p className="footdesc">
                Get in touch with us through our contact form or by visiting one
                of our locations.
                <br /> (+91) 1234567891   ,  (+91) 9876543219
              </p>
            </div>
            <div className="col-md-4">
              <h6 className="foottopic">Legal Information</h6>
              <p className="footdesc">
                Access our legal information, terms and conditions, and privacy
                policy.
              </p>
            </div>
          </div>
        </div>
        <div className="copyright">
          <p className="copy">
            &copy; 2023 AEONIX. All rights reserved. <br />
            The content, design, and functionality of this website are the
            exclusive property of AEONIX and are protected by
            international copyright laws. Unauthorized reproduction,
            distribution, or modification of any portion of this website is
            strictly prohibited.
            <br />
            <br />
            All trademarks, logos, and service marks displayed on this site are
            the property of AEONIX or other third parties, and their
            use is subject to applicable trademark laws.
            <br />
            <br />
            Your use of this website constitutes your agreement to abide by our{' '}
            <a href="/privacy-policy">Privacy Policy</a> and{' '}
            <a href="/terms-of-service">Terms of Service</a>.
            <br />
            <br />
            For inquiries regarding the use or licensing of our copyrighted
            materials, please contact us at legal@aeonix.com.
          </p>
        </div>
        <ul className="social-links">
          <li>
            <a href="#" target="_blank">
              <p><i className="fa-brands fa-facebook"></i> Facebook </p>
            </a>
          </li>
          <li>
            <a href="#" target="_blank">
              <p><i className="fab fa-instagram"></i> Instagram </p>
            </a>
          </li>
          <li>
            <a href="#" target="_blank">
              <p><i className="fab fa-twitter"></i> Twitter </p>
            </a>
          </li>
          <li>
            <a href="#" target="_blank">
              <p><i className="fab fa-linkedin"></i> LinkedIn </p>
            </a>
          </li>
        </ul>
      </footer>
    
  );
};

export default Footer;