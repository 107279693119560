import React, { useRef } from "react";
import { Preload, useGLTF } from "@react-three/drei";
import { useFrame, useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";
import { MeshReflectorMaterial } from "@react-three/drei";


export const Tesla= (props) => {
  const model = useGLTF("/models/scene.gltf");
  const modelRef = useRef();    
  
  const rotateModel = () => {
    // Rotate the model on the Y-axis (you can adjust the rotation axis as needed)
    modelRef.current.rotation.y += 0.0044;
  };

  useFrame(rotateModel);

  
 

  return (
    <group {...props} dispose={null} scale={[0.1, 0.1, 0.1]}>
      <primitive object={model.scene} ref={modelRef} position={[0, -12.2,0]} scale={[.05, .05, .05]} />
     
                 
                
                    <directionalLight intensity={1} />
                    <pointLight intensity={1} position={[0, 5, 10]} /> 
                    <ambientLight intensity={1} />
                    <rectAreaLight intensity={1} position={[0, 10, 0]} width={10} height={10} />

                   
                </group>
            );
        };
        export function Tesla_Details(){
          const handlebuy = async (e) => {
            e.preventDefault();
        
           
        
            try {
                const response = await fetch(`http://localhost:4321/create-checkout-session/price_1OIch9SEA4du02ST7aPYvJWv`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({})
                });
        
                if (response.ok) {
                    const jsonResponse = await response.json();
                    window.open(jsonResponse.url, '_blank');
                } else {
                    throw new Error('Network response was not ok.');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };
          return <div id="Car-Details-Container">
            <div id="Car-Name">Tesla</div>
            <div id ="Car-Specs-Container">
              <div id="Car-Specs-Title">SPECIFICATIONS</div>
              <div id="Car-Specs-List">
                <div id="Car-Specs-Item">Engine: <br /> Electric</div>
                <div id="Car-Specs-Item">Horse Power: <br /> 670 hp</div>
                <div id="Car-Specs-Item">0-60: <br /> 2.3 seconds</div>
                <div id="Car-Specs-Item">Top Speed: <br /> 200 mph</div>
            </div></div>
            <div id="Car-Description">The Tesla Model S is a luxury all-electric sedan produced by Tesla, Inc. It is known for its high performance, long range, and advanced technology features.</div>
            <div id="Car-Price">Price: $79,990</div>
            <button id="Car-Buy-Button"   onClick={handlebuy}>Buy</button>
            </div>
        }
     